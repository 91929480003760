:root {
  --purple: rgb(99, 81, 206);
  --blue: #0d6efd;
  --grey: #e7e8e9;
}
.body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.grow {
  transition: 0.3s ease;
}

.grow:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px black;
}
.grow:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  transition: 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 0 5px black;
}
.socialMediaIconColor {
  color: white;
  font-size: 20px;
}
.heading {
  /* background: var(--blue); */
  background: rgb(13, 110, 253);
  background: linear-gradient(
    90deg,
    rgba(13, 110, 253, 1) 0%,    
    rgba(0, 142, 255, 1) 35%,
    rgba(0, 212, 255, 1) 100%
    
  );
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  color: white;
  margin: 10px 0;
  font-size:24px;
}
.topHeading{
  font-size:24px;

}
/* Use for home page top sellling packges card title */
.blue-gradient-bg {
  background: var(--grey);
}


