.display{
    /* background-color: var(--blue); */
    background: rgb(13, 110, 253);
    background: linear-gradient(
      90deg,
      rgba(13, 110, 253, 1) 0%,
      rgba(0, 142, 255, 1) 35%,
      rgba(0, 212, 255, 1) 100%
    );
    height:200px;
    margin-bottom: 40px;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}